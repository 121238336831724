// extracted by mini-css-extract-plugin
export var project = "project-module--project--hIesq";
export var image = "project-module--image--bIJ6-";
export var even = "project-module--even--5YKu1";
export var text = "project-module--text--DILDX";
export var header = "project-module--header--ioLWw";
export var title = "project-module--title--KaXEv";
export var description = "project-module--description--e0fJ3";
export var stackItem = "project-module--stackItem--8Kjtm";
export var link = "project-module--link--udRo5";
export var tooltip = "project-module--tooltip--9RYxs";