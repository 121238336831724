// extracted by mini-css-extract-plugin
export var cursor = "index-module--cursor--R0XFW";
export var blink = "index-module--blink--Mix6V";
export var banner = "index-module--banner--TOE4n";
export var bannerContainer = "index-module--bannerContainer--ufOJI";
export var bannerTitle = "index-module--bannerTitle--Om+E8";
export var brief = "index-module--brief--FSF-A";
export var skroutz = "index-module--skroutz--ZHa0h";
export var tech = "index-module--tech--9b1gO";
export var articleHeading = "index-module--articleHeading--S+N3r";
export var techHeading = "index-module--techHeading--EgUKX";
export var sectionHeading = "index-module--sectionHeading--hkHoT";
export var indexSection = "index-module--indexSection--Ymhzp";
export var wipList = "index-module--wipList--Q-uv8";
export var wipItem = "index-module--wipItem--6TQZ+";